import './assets/main.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import "element-plus/dist/index.css";
import vue3GoogleLogin from 'vue3-google-login';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { createPinia } from 'pinia'
if (localStorage.getItem('user') === '{}' || !localStorage.getItem('user')) {
    localStorage.setItem('isLogin', false);
} else {
    localStorage.setItem('isLogin', true)
}
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(ElementPlus)
app.use(router)

import gAuthPlugin from 'vue3-google-oauth2';
// let gAuthClientId = '256487807007-a8gak4d7fa3p8u3magtbphceiovjvh9u.apps.googleusercontent.com';     //Ian 
// let gAuthClientId = '96731756559-d810sqop5jo4bkm6p2a7ce2vadgajndd.apps.googleusercontent.com';       //Dante
// let gAuthClientId = '676785461988-icoil0dtlld2fcp5kb22llst7t94mans.apps.googleusercontent.com';      //Test
let gAuthClientId = '256487807007-1pb80afne0r1t62ottgj8kdrg9niqqv7.apps.googleusercontent.com';

// app.use(gAuthPlugin, {
//     clientId: gAuthClientId,
//     scope: 'email',
//     prompt: 'consent',
// })

app.use(vue3GoogleLogin, {
    clientId: '256487807007-1pb80afne0r1t62ottgj8kdrg9niqqv7.apps.googleusercontent.com'
})
app.use(createPinia())
app.mount('#app')
